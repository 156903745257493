<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link class="brand-logo">
          <b-img
              :src="require('@/assets/images/logo/logo.png')"
              alt="logo"
          />

          <h2 class="brand-text text-primary ml-1">
            ERP
            <small>
              <i>by HMT</i>
            </small>
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('ResetPassword') }} 🔒
        </b-card-title>
        <!--        <b-card-text class="mb-2">-->
        <!--          {{$t('Your new password must be different from previously used passwords')}}-->
        <!--        </b-card-text>-->

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
              method="POST"
              class="auth-reset-password-form mt-2"
              @submit.prevent="validationForm"
          >

            <!-- password -->
            <b-form-group
                :label="capitalize($t('newPassword'))"
                label-for="reset-password-new"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('password')"
                  vid="Password"
                  rules="required|password"
              >
                <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <icon class="cursor-pointer"
                          :icon="password1ToggleIcon"
                          @click.native="togglePassword1Visibility"/>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
                label-for="reset-password-confirm"
                :label="capitalize($t('confirmPassword'))"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('confirmPassword')"
                  rules="required|confirmed:Password"
              >
                <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                      id="reset-password-confirm"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <icon class="cursor-pointer"
                          :icon="password2ToggleIcon"
                          @click.native="togglePassword2Visibility"/>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
                block
                type="submit"
                variant="primary"
            >
              {{ $t('SetNewPassword') }}
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <icon icon="chevron-left"/>
            {{ $t('BackToLogin') }}
          </b-link>
        </p>

      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@/utils/validations/validations'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  setup () {
    return { capitalize }
  },
  data () {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon () {
      return this.password1FieldType === 'password' ? 'eye' : 'eye-slash'
    },
    password2ToggleIcon () {
      return this.password2FieldType === 'password' ? 'eye' : 'eye-slash'
    },
  },
  methods: {
    togglePassword1Visibility () {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility () {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm () {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let resetToken = this.$route.params.resetToken
          let datas = new FormData()
          datas.set('plainPassword', this.password)

          this.$store.dispatch('auth/resetPassord', { resetToken, datas })
              .then(response => {
                console.log(response.data)

                if (response.data == false) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t('Error'),
                      icon: 'times',
                      text: this.$t('ExpiredRequest'),
                      variant: 'danger',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t('Success'),
                      icon: 'check',
                      text: this.$t('FormSubmitted'),
                      variant: 'success',
                    },
                  })

                  this.$router.push({ name: 'auth-login' })
                }

              })
              .catch(error => {
                console.log(error)
              })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@/assets/scss/base/pages/auth.scss";
</style>
